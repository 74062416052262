import React from 'react'
import { ButtonSize, PrimaryButton } from 'streamr-ui'
import { Bold, MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled, { css, keyframes } from 'styled-components'
import star from './assets/star.png'
import star2x from './assets/star@2x.png'
import tatum from './assets/tatum.png'
import tatum2x from './assets/tatum@2x.png'

const bounce = keyframes`
    0% {
        transform: translateX(-50%) translateY(0);
    }
    50% {
        transform: translateX(-50%) translateY(2%);
    }
    100% {
        transform: translateX(-50%) translateY(0);
    }
`

const bounceY = keyframes`
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(40%);
    }
    100% {
        transform: translateY(0);
    }
`

const shift = keyframes`
    0% {
        transform: translateX(-2%) scale(1);
    }
    50% {
        transform: translateX(2%) scale(0.92);
    }
    100% {
        transform: translateX(-2%) scale(1);
    }
`

export default function Tatum() {
    return (
        <Root>
            <GlobeImage src={tatum} srcSet={`${tatum2x} 2x`} alt="Tatum" />
            <Star0Wrap>
                <Star0 $duration={12}>
                    <img src={star} srcSet={`${star2x} 2x`} alt="Star" />
                </Star0>
            </Star0Wrap>
            <Star1Wrap>
                <Star1>
                    <img src={star} srcSet={`${star2x} 2x`} alt="Star" />
                </Star1>
            </Star1Wrap>
            <Content>
                <h2 $appear>Streamr 1.0 is&nbsp;coming!</h2>
                <p $appear>
                    The complete DATA tokenomics will be in released at the &quot;Streamr 1.0&quot;
                    milestone. Streamr node operators will secure and relay data on streams
                    associated to Sponsorships. Delegators delegate to Operators and earn a revenue
                    share of the Operator&apos;s work in&nbsp;return.
                </p>
                <div $appear>
                    <PrimaryButton
                        tag="a"
                        size={ButtonSize.Moose}
                        paint={CustomPaint}
                        href="https://blog.streamr.network/streamr-token-economics/"
                    >
                        Learn about Streamr 1.0
                    </PrimaryButton>
                </div>
            </Content>
        </Root>
    )
}

const Star = styled.div`
    animation: ${shift} ${({ $duration = 7 }) => $duration}s infinite ease-in-out;

    img {
        animation: ${bounceY} ${({ $duration = 7 }) => 5 * $duration}s infinite ease-in-out;
        display: block;
        height: 100%;
        width: 100%;
    }
`

const StarWrap = styled.div`
    display: none;
    position: absolute;
    bottom: 0;
    left: 50%;

    @media ${MqTablet} {
        display: block;
    }
`

const Star0Wrap = styled(StarWrap)`
    transform: translate(-50%, 50%) translate(-22rem, -21rem);

    @media ${MqDesktop} {
        transform: translate(-50%, 50%) translate(-27rem, -21rem);
    }
`

const Star0 = styled(Star)`
    width: 12rem;
    height: 12rem;

    @media ${MqDesktop} {
        width: 16rem;
        height: 16rem;
    }
`

const Star1Wrap = styled(Star0Wrap)`
    transform: translate(-50%, 50%) translate(19rem, -32rem);

    @media ${MqDesktop} {
        transform: translate(-50%, 50%) translate(27rem, -32rem);
    }
`

const Star1 = styled(Star)`
    width: 7.25rem;
    height: 7.25rem;

    @media ${MqDesktop} {
        width: 9.75rem;
        height: 9.75rem;
    }
`

const GlobeImage = styled.img`
    bottom: 0;
    display: block;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    user-select: none;
    width: 558px;
    animation: ${bounce} 10s infinite ease-in-out;

    @media ${MqTablet} {
        width: 769px;
    }

    @media ${MqDesktop} {
        width: 802px;
    }
`

const Root = styled.div`
    background: linear-gradient(0deg, #ddd1c2 0%, #dad6cf 34%, rgba(245, 245, 245, 0) 100%),
        radial-gradient(farthest-side at 0 100%, #ddd1c2 0%, rgba(245, 245, 245, 0) 80%);
    background-blend-mode: multiply;
    overflow: hidden;
    padding-bottom: 25.5rem;
    position: relative;
    text-align: center;

    h2 {
        font-size: 2rem;
        font-weight: ${Bold};
        margin: 0;
        padding: 0 2rem;
    }

    p {
        max-width: 90%;
        margin: 2em auto 3.5em;
        line-height: 1.625em;
        width: 32rem;
    }

    @media ${MqTablet} {
        padding-bottom: 35rem;

        h2 {
            font-size: 3.75rem;
        }

        p {
            font-size: 1.125rem;
            max-width: 582px;
            width: auto;
        }
    }

    @media ${MqDesktop} {
        padding-bottom: 29rem;

        h2 {
            font-size: 5.5rem;
        }

        p {
            font-size: 1.25rem;
            max-width: 43rem;
        }
    }
`

const Content = styled.div`
    position: relative;
`

const CustomPaint = css`
    --radius: 8px;
`
