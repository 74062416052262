export default [
    'https://brubeck3.streamr.network:30401',
    'https://brubeck3.streamr.network:30402',
    'https://brubeck3.streamr.network:30403',
    'https://brubeck3.streamr.network:30404',
    'https://brubeck3.streamr.network:30405',
    'https://brubeck4.streamr.network:30401',
    'https://brubeck4.streamr.network:30402',
    'https://brubeck4.streamr.network:30403',
    'https://brubeck4.streamr.network:30404',
    'https://brubeck4.streamr.network:30405',
]
