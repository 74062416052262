import { Link as GLink } from 'gatsby'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Element as UnstyledElement, animateScroll as scroll } from 'react-scroll'
import { Button, ButtonSize, Link, OutlinedButton, PrimaryButton } from 'streamr-ui'
import { Bold, MqDesktop, MqTablet, Tablet } from 'streamr-ui/css/consts'
import styled, { css } from 'styled-components'
import useIsMounted from '~hooks/useIsMounted'
import ButtonWrapper from '~shared/ButtonWrapper'
import PrestyledContainer from '~shared/Container'
import InteractionEncourager from '~shared/InteractionEncourager'
import LoadingIndicator from '~shared/LoadingIndicator'
import PrestyledNav from '~shared/Nav'
import NetworkStats from '~shared/NetworkStats'
import NetworkExplorer from '~shared/NetworkVisualizer/NetworkExplorer'
import ThemeProvider from '~shared/NetworkVisualizer/ThemeProvider'
import ScrollEncourager, { scrollToTarget } from '~shared/ScrollEncourager'
import Spacer from '~shared/Spacer'
import ScrollPhaseManager, { MinHeight } from '~utils/ScrollPhaseManager'
import urls from '~utils/urls'
import { Countdown, CountdownRoot } from '~/components/Countdown'
import { useMediaQuery } from 'react-responsive'

const TestnetStartDate = '2024-01-25T15:00:00Z'

const TestnetEndDate = '2024-02-08T15:00:00Z'

const UnstyledHero = ({ className }) => {
    const contentRef = useRef(null)

    const [masked, setMasked] = useState(true)

    const [unmasked, setUnmasked] = useState(false)

    const spmRef = useRef(
        new ScrollPhaseManager({
            onUpdate(phase) {
                if (!contentRef.current) {
                    return
                }

                contentRef.current.style.transform = `translateY(-${(1 - phase) * 100}%)`

                contentRef.current.style.opacity = phase ** 4
            },

            onBeforeStart(targetPhase) {
                setMasked(targetPhase !== 0)
            },

            onComplete(phase) {
                if (phase === 0) {
                    setUnmasked(true)
                }
            },

            onThresholdUpdate(t) {
                const el = document.getElementsByName('ScrollEncouragerTarget')?.[0]

                if (!el) {
                    return
                }

                if (window.innerHeight >= MinHeight) {
                    const { top, ...newStyle } = el.style

                    el.style = newStyle
                } else {
                    el.style.top = `${Math.ceil(t)}px`
                }
            },
        }),
    )

    useEffect(() => {
        const { current: spm } = spmRef

        spm.mount()

        return () => {
            spm.unmount()
        }
    }, [])

    const [data, setData] = useState()

    const [fetching, setFetching] = useState(false)

    const loading = !data || fetching

    const [point, setPoint] = useState()

    const [ready, setReady] = useReducer(() => true, false)

    const isMounted = useIsMounted()

    const isTablet = useMediaQuery({ minWidth: Tablet })

    return (
        <>
            <div className={className}>
                <ThemeProvider>
                    <SpaceholderRail />
                    <Rail>
                        <Cart>
                            <VisualizerInner>
                                {ready && unmasked && <InteractionEncourager />}
                                <LoadingIndicatorWrap>
                                    <LoadingIndicator large loading={loading} />
                                </LoadingIndicatorWrap>
                                <NetworkExplorer
                                    onIntroDone={setReady}
                                    onLoaded={setData}
                                    onNodeChange={setPoint}
                                    setLoading={(value) => {
                                        if (isMounted()) {
                                            setFetching(value)
                                        }
                                    }}
                                    onPositionCorrection={({ top, bottom }) => {
                                        if (top) {
                                            scroll.scrollToTop()
                                        }

                                        if (bottom) {
                                            scrollToTarget()
                                        }
                                    }}
                                />
                            </VisualizerInner>
                        </Cart>
                    </Rail>
                    <Rail $interactive={masked}>
                        <ContentCart>
                            <ContentWrap>
                                <Container>
                                    <Body ref={contentRef}>
                                        <h1>
                                            Decentralized
                                            <br />
                                            data broadcasting
                                        </h1>
                                        <Spacer as="p" head="24px,," tail="48px,56px,32px">
                                            Streamr hyperscales live data for AI, video, and DePIN{' '}
                                            <DesktopBr />
                                            via secure P2P distribution.
                                        </Spacer>
                                        <ButtonWrapper noAppear>
                                            <PrimaryButton
                                                tag={Link}
                                                size={ButtonSize.Moose}
                                                href={urls.stakeAndEarn}
                                            >
                                                Become an Operator
                                            </PrimaryButton>
                                        </ButtonWrapper>
                                        <ButtonWrapper noAppear>
                                            <OutlinedButton
                                                tag={GLink}
                                                size={ButtonSize.Moose}
                                                to={urls.devs}
                                            >
                                                Create streams
                                            </OutlinedButton>
                                        </ButtonWrapper>
                                    </Body>
                                </Container>
                            </ContentWrap>
                        </ContentCart>
                    </Rail>
                    <Rail $interactive={false}>
                        <ContentCart>
                            <ContentWrap />
                            <EncouragerWrap $visible={isTablet || !masked}>
                                <EncouragerInnerWrap>
                                    <ScrollEncourager center noAppear />
                                </EncouragerInnerWrap>
                            </EncouragerWrap>
                        </ContentCart>
                    </Rail>
                    <Rail $interactive={false} $bringToFront>
                        <Cart>
                            <Nav
                                light
                                afterLogoComponent={
                                    <StatsWrap>
                                        <TestnetCountdown
                                            startDate={TestnetStartDate}
                                            endDate={TestnetEndDate}
                                            fallback={<NetworkStats data={data} point={point} />}
                                            enabled={isTablet}
                                        />
                                    </StatsWrap>
                                }
                            />
                            <TestnetCountdown
                                startDate={TestnetStartDate}
                                endDate={TestnetEndDate}
                                fallback={null}
                                enabled={!isTablet}
                            />
                        </Cart>
                    </Rail>
                </ThemeProvider>
            </div>
            <Element name={ScrollEncourager.TargetID} $masked={masked} />
        </>
    )
}

const Hero = styled(UnstyledHero)`
    background-color: #0a1433;
`

export default Hero

const Element = styled(UnstyledElement)`
    ${({ $masked }) =>
        $masked &&
        css`
            position: absolute;
            top: 72px;

            @media (min-height: 720px) {
                top: 50vh;
            }
        `}
`

const Nav = styled(PrestyledNav)`
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`

const StatsWrap = styled.div`
    flex-grow: 1;
    margin-left: 24px;

    ${CountdownRoot},
    ${NetworkStats} {
        margin: 0 auto;
    }

    @media ${MqDesktop} {
        ${CountdownRoot},
        ${NetworkStats} {
            margin: 0;
        }
    }
`

const LoadingIndicatorWrap = styled.div`
    height: 0;
    overflow: visible;
`

const VisualizerInner = styled.div`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`

const Cart = styled.div`
    height: 100vh;
    overflow: hidden;
    position: sticky;
    top: 0;
    width: 100%;

    > ${CountdownRoot} {
        position: absolute;
        top: 64px;
        left: 50%;
        transform: translateX(-50%);
    }
`

const SpaceholderRail = styled.div`
    height: 150vh;
    min-height: 1080px;
`

const Body = styled.div`
    color: white;
    text-align: center;

    h1 {
        font-weight: ${Bold};
        margin: 0;
        font-size: 32px;
        line-height: 40px;
    }

    p {
        font-size: 18px;
        line-height: 26px;
        margin-left: auto;
        margin-right: auto;
        max-width: 426px;
    }

    ${Button},
    ${ButtonWrapper} {
        width: 100%;
    }

    ${ButtonWrapper} {
        margin-left: auto;
        margin-right: auto;
        max-width: 400px;
    }

    ${ButtonWrapper} + ${ButtonWrapper} {
        margin-top: 16px;
    }

    @media ${MqTablet} {
        h1 {
            font-size: 40px;
            line-height: 52px;
        }

        p {
            font-size: 24px;
            line-height: 34px;
            max-width: 492px;
        }

        ${ButtonWrapper} {
            width: 160px;
        }

        ${ButtonWrapper} + ${ButtonWrapper} {
            margin-left: 32px;
        }
    }

    @media ${MqDesktop} {
        text-align: left;

        h1 {
            font-size: 88px;
            line-height: 1.2em;
        }

        p {
            font-size: 24px;
            line-height: 46px;
            max-width: 800px;
            margin-left: 0;
            margin-right: 0;
        }

        ${ButtonWrapper} {
            width: 192px;
        }

        ${Button} {
            display: inline-flex;
        }
    }
`

const DesktopBr = styled.span`
    @media ${MqDesktop} {
        display: block;
    }
`

const Rail = styled.div`
    height: 150vh; // 50% vh extra
    left: 0;
    min-height: 1080px;
    position: absolute;
    top: 0;
    width: 100%;

    ${({ $interactive = true }) =>
        !$interactive &&
        css`
            &,
            ${Body} {
                pointer-events: none;
            }
        `}

    ${({ $bringToFront = false }) =>
        $bringToFront &&
        css`
            z-index: 1;
        `}
`

const ContentWrap = styled.div`
    align-items: center;
    display: flex;
    flex-basis: calc(720px - 48px - 100px);

    :only-child {
        flex-basis: 720px;
    }

    @media (min-height: 720px) {
        flex-basis: calc(100vh - 48px - 100px);

        :only-child {
            flex-basis: 100vh;
        }
    }

    @media ${MqTablet} {
        &,
        :only-child {
            flex-basis: calc(720px - 48px - 100px);
        }

        @media (min-height: 720px) {
            &,
            :only-child {
                flex-basis: calc(100vh - 48px - 100px);
            }
        }
    }
`

const EncouragerWrap = styled.div`
    align-items: flex-end;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    transition: 250ms;
    transition-property: visibility, opacity;
    transition-delay: 250ms, 0s;
    opacity: 0;
    visibility: hidden;

    ${ScrollEncourager} {
        pointer-events: auto;
    }

    ${({ $visible = false }) =>
        $visible &&
        css`
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
        `}
`

const ContentCart = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (min-height: 720px) {
        height: 100vh;
        min-height: 720px;
        position: sticky;
        top: 0;
    }
`

const EncouragerInnerWrap = styled.div`
    bottom: 0;
    padding: 0 0 100px;
    position: sticky;
`

const Container = styled(PrestyledContainer)`
    position: relative;
    z-index: 1;

    @media ${MqDesktop} {
        box-sizing: border-box;
        max-width: 100%;
        padding-left: 128px;
        padding-right: 128px;
        width: 1416px;
    }
`

function TestnetCountdown({ startDate, endDate, fallback, enabled = false }) {
    const props = {
        href: 'https://docs.streamr.network/streamr-testnets/testnets',
        rel: 'noopener noreferrer',
        target: '_blank',
    }

    return (
        <Countdown targetDate={startDate}>
            {(testnetStarted, startsIn) =>
                testnetStarted ? (
                    <Countdown targetDate={endDate}>
                        {(testnetEnded, endsIn) =>
                            testnetEnded
                                ? fallback
                                : enabled && (
                                      <CountdownRoot {...props}>
                                          <p>
                                              Streamr 1.0 Testnet 3 incentives end&nbsp;in:{endsIn}
                                          </p>
                                      </CountdownRoot>
                                  )
                        }
                    </Countdown>
                ) : (
                    enabled && (
                        <CountdownRoot {...props}>
                            <p>Streamr 1.0 Testnet 3 incentives begin&nbsp;in:{startsIn}</p>
                        </CountdownRoot>
                    )
                )
            }
        </Countdown>
    )
}
