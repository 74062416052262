import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { Medium, MqTablet } from 'streamr-ui/css/consts'
import { OutlinedButton, ButtonPaint } from 'streamr-ui'
import { Link } from 'gatsby'
import Topic from '~shared/Topic'
import Arweave from './icons/arweave.svg'
import Dimo from './icons/dimo.svg'
import Ethereum from './icons/ethereum.svg'
import Gnosis from './icons/gnosis.svg'
import Helium from './icons/helium.svg'
import Polygon from './icons/polygon.svg'
import RedStone from './icons/redStone.svg'
import Swash from './icons/swash.svg'

const Logo = {
    Arweave,
    Dimo,
    Ethereum,
    Gnosis,
    Helium,
    Polygon,
    RedStone,
    Swash,
}

function w(mobile, tablet = mobile, desktop = mobile) {
    return css`
        img& {
            --width: ${mobile}px;
            --tabletWidth: ${tablet}px;
            --desktopWidth: ${desktop}px;
        }
    `
}

function UnstyledPartners(props, ref) {
    return (
        <div {...props} ref={ref} $appearIntersection>
            <h2 $appear>Streamr ecosystem</h2>
            <ul>
                <li $appear>
                    <img width="144" height="36" src={Logo.Ethereum} alt="" css={w(96, 112, 144)} />
                </li>
                <li $appear>
                    <img width="138" height="30" src={Logo.Polygon} alt="" css={w(83, 92, 138)} />
                </li>
                <li $appear>
                    <img width="101" height="32" src={Logo.Gnosis} alt="" css={w(62, 75, 100)} />
                </li>
                <li $appear>
                    <img width="136" height="32" src={Logo.Arweave} alt="" css={w(84, 101, 136)} />
                </li>
                <li $appear>
                    <img width="75" height="22" src={Logo.Swash} alt="" css={w(70, 84, 97)} />
                </li>
                <li $appear>
                    <img width="59" height="20" src={Logo.Dimo} alt="" css={w(53, 59, 71)} />
                </li>
                <li $appear>
                    <img width="100" height="32" src={Logo.Helium} alt="" css={w(62, 74, 99)} />
                </li>
                <li $appear>
                    <img width="120" height="23" src={Logo.RedStone} alt="" css={w(87, 109, 152)} />
                </li>
            </ul>
            <div $appear>
                <OutlinedButton tag={Link} paint={ButtonPaint.PrimaryOutlined} to="/ecosystem">
                    Explore ecosystem
                </OutlinedButton>
            </div>
        </div>
    )
}

const ReffedPartners = forwardRef(UnstyledPartners)

const Partners = styled(ReffedPartners)`
    background-color: white;
    padding: 0 32px;
    position: relative;
    transition: 200ms ease-in-out;
    transition-property: background-color, padding-top, padding-bottom;

    h2 {
        color: #0c009a;
        font-size: 14px;
        font-weight: ${Medium};
        letter-spacing: 0.14em;
        margin: 0;
        max-width: 100%;
        overflow: hidden;
        padding: 0 32px;
        text-align: center;
        text-overflow: ellipsis;
        text-transform: uppercase;
        white-space: nowrap;
    }

    ul {
        background-color: white;
        border-radius: 16px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        list-style: none;
        margin: 56px auto 0;
        max-width: 1160px;
        padding: 0;
        row-gap: 48px;
        transition: 200ms ease-in-out;
        transition-property: row-gap, padding-top, padding-bottom;
    }

    li {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        height: 60px;
        justify-content: center;
    }

    > div {
        margin-top: 60px;
        text-align: center;
    }

    img {
        --width: 100px;
        --tabletWidth: var(--width);
        --desktopWidth: var(--width);

        display: block;
        margin: 0 auto;
        max-width: 100%;
        transition: 200ms ease-in-out width;
        width: var(--width);
    }

    @media (min-width: 592px) {
        ul {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    @media ${MqTablet} {
        padding: 0 32px;

        ul {
            row-gap: 56px;
        }

        img {
            width: var(--tabletWidth);
        }
    }

    @media (min-width: 960px) {
        background-color: transparent;
        padding: 0 32px;

        ul {
            padding: 64px 0;
            row-gap: 60px;
        }

        img {
            width: var(--desktopWidth);
        }
    }
`

export default Partners

export const PartnersTopic = styled(Topic)`
    background-color: white;

    @media (min-width: 960px) {
        background-color: transparent;
    }
`
