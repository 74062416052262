import { useReducer } from 'react'
import { useSubscribe } from 'streamr-client-react'
import useIsMounted from '~hooks/useIsMounted'

const reducer = (state, data) => {
    const { broker, trackers, staking, network } = data || {}

    return {
        messagesPerSecond:
            (network &&
                network.publishMessagesPerSecond &&
                Math.round(network.publishMessagesPerSecond)) ||
            (broker &&
                broker.messagesToNetworkPerSec &&
                Math.round(broker.messagesToNetworkPerSec)) ||
            undefined,
        numberOfNodes:
            (network && network.totalNumberOfNodes) ||
            (trackers && trackers.totalNumberOfNodes) ||
            undefined,
        apy: staking ? staking['24h-APY'] : undefined,
        ready: !!data,
    }
}

export default function useNetworkStats() {
    const [stats, updateStats] = useReducer(reducer, reducer())

    const isMounted = useIsMounted()

    useSubscribe('streamr.eth/metrics/network/sec', {
        onMessage(msg) {
            if (!isMounted()) {
                return
            }

            updateStats(msg.getParsedContent())
        },
        resendOptions: {
            last: 1,
        },
        onError(e) {
            console.error(e)
        },
        onMessageError(e) {
            console.error(e)
        },
    })

    return stats
}
