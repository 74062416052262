import { get } from './fetchData'

async function getNodeInfo(nodeId, trackerURL) {
    try {
        const streams = await get(`${trackerURL}/nodes/${encodeURIComponent(nodeId)}/streams`)

        let streamId = null

        streams.reduce((maxSize, { streamId: id, topologySize }) => {
            if (topologySize > maxSize) {
                streamId = id
                return topologySize
            }

            return maxSize
        }, -1)

        return streamId
    } catch (e) {
        return null
    }
}

function setMapValue(map, key, value) {
    if (typeof value === 'function') {
        const newValue = value(map.get(key))
        map.set(key, newValue)

        return newValue
    }

    map.set(key, value)

    return value
}

async function getStreamTopology(streamId, trackerURL, { nodeCoordinates = {} } = {}) {
    const topology = []

    const uniqueNodeIds = {}

    try {
        const trackedTopology = await get(`${trackerURL}/topology/${encodeURIComponent(streamId)}`)

        const cache = new WeakMap()

        Object.values(trackedTopology).forEach((rawTopology) => {
            Object.entries(rawTopology).forEach(([nodeId, neighbors]) => {
                uniqueNodeIds[nodeId] = true

                const a = nodeCoordinates[nodeId]

                if (!a) {
                    return
                }

                const cacheA = setMapValue(cache, a, (current) => current || new Set())

                neighbors.forEach(({ neighborId }) => {
                    uniqueNodeIds[neighborId] = true

                    const b = nodeCoordinates[neighborId]

                    if (!b || (cache.get(b) || new Set()).has(a) || cacheA.has(b)) {
                        // Filter out redundancy.
                        return
                    }

                    cacheA.add(b)
                    topology.push([a, b])
                })
            })
        })
    } catch (e) {
        console.warn("Failed to build a complete stream's topology")
    }

    return [topology, Object.keys(uniqueNodeIds).length]
}

export default async function getTopology(
    point,
    { nodeCache = {}, streamCache = {}, metadata = new WeakMap(), nodeCoordinates = {} } = {},
) {
    const { nodeId, trackerURL } = metadata.get(point)

    if (!nodeId) {
        // Someone called this function with a point that does not have corresponding entry
        // in the `metadata` collection. Should not happen unless somebody's effing around.
        throw new Error('Unknown node')
    }

    const streamId = await (async () => {
        if (typeof nodeCache[nodeId] === 'undefined') {
            // Keep track of promises (settled and not) and reuse them in consecutive calls to
            // this function. We don't use `await` here because we're interested in a Promise, not
            // its result.
            nodeCache[nodeId] = getNodeInfo(nodeId, trackerURL)
        }

        try {
            // Each nodeCache[*] is a Promise. Each may be settled by this moment. Or we need
            // to wait. All taken care of here. Here, your `await`. ;)
            return await nodeCache[nodeId]
        } catch (e) {
            // Cache `null` if things go south and the loading fails. Worst case scenario. Don't
            // think about it.
            nodeCache[nodeId] = Promise.resolve(null)
        }

        return null
    })()

    if (streamId === null) {
        // No stream id? Let's give it empty topology. Simple as that.
        return []
    }

    setMapValue(metadata, point, (current) => ({
        ...current,
        streamId,
    }))

    const [topology, topologySize] = await (async () => {
        if (typeof streamCache[streamId] === 'undefined') {
            // Same story as with `nodeCache`. We keep track of promises, not results.
            streamCache[streamId] = getStreamTopology(streamId, trackerURL, {
                nodeCoordinates,
                metadata,
            })
        }

        try {
            // Each streamCache[*] is a Promise. Each may be settled by this moment. Or we need
            // to wait. All taken care of here. Here, your `await`. ;)
            return await streamCache[streamId]
        } catch (e) {
            // Cache `{}` if things go south and the loading fails. Worst case scenario. Don't
            // think about it.
            streamCache[streamId] = Promise.resolve([[], 0])
        }

        return [[], 0]
    })()

    setMapValue(metadata, point, (current) => ({
        ...current,
        topologySize,
    }))

    return topology
}
