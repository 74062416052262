import { providers } from 'ethers'
import { Vector3 } from 'three'
import { get } from './fetchData'
import fallbackTrackers from './trackers'
import { LATI_TRANS, LONG_TRANS } from '~shared/NetworkVisualizer'
import { Y_POS } from '~shared/NetworkVisualizer/Particles'

export default async function feed() {
    const coordinates = []

    const metadata = new WeakMap()

    const nodeCoordinates = {}

    const urls = await (async () => {
        try {
            const { getTrackerRegistryFromContract } = await import('streamr-client')

            const t = await getTrackerRegistryFromContract({
                contractAddress: '0xab9BEb0e8B106078c953CcAB4D6bF9142BeF854d',
                jsonRpcProvider: new providers.JsonRpcProvider(
                    'https://mainnet.infura.io/v3/17c3985baecb4c4d94a1edc2c4d23206',
                ),
            })

            return t.getAllTrackers().map(({ http }) => http)
        } catch (e) {
            console.warn(
                'Failed to fetch tracker URLs from the registry. Using the fallback collection.',
            )
        }

        return fallbackTrackers
    })()

    await Promise.all(
        urls.map(async (trackerURL) => {
            const collection = await (async () => {
                try {
                    return await get(`${trackerURL}/location/`)
                } catch (e) {
                    console.warn(`Failed to load node details from "${trackerURL}"`)
                }

                return {}
            })()

            Object.entries(collection).forEach(([nodeId, { latitude, longitude, country }]) => {
                const v3 = new Vector3(longitude * LONG_TRANS, Y_POS, -latitude * LATI_TRANS)

                const hit = coordinates.find((v) => v3.distanceToSquared(v) < 0.0005)

                if (!hit) {
                    coordinates.push(v3)
                    metadata.set(v3, {
                        country,
                        latitude,
                        longitude,
                        nodeId,
                        trackerURL,
                    })
                }

                nodeCoordinates[nodeId] = hit || v3
            })
        }),
    )

    return {
        coordinates,
        metadata,
        nodeCoordinates,
    }
}
