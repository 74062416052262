import {
    PlaneGeometry,
    Mesh,
    MeshPhongMaterial,
    Color,
    LinearToneMapping,
    PerspectiveCamera,
    Scene,
    Vector3,
    Vector4,
    WebGLRenderer,
} from 'three'
import Lights from './Lights'
import Controls from './Controls'
import RenderPipeline from './post/RenderPipeline'
import QA from './Qa'
import Lines from './Lines'
import NodeHighlightAnimation from './NodeHighlightAnimation'
import Particles from './Particles'
import Autopilot from './Autopilot'
import getHits from './utils/getHits'
import getCoordinatesArea from './utils/getCoordinatesArea'
import injectRenderPipeline from './utils/injectRenderPipeline'
import postpone from './utils/postpone'
import getTopology from './utils/getTopology'
import Earth from './utils/Earth'
import defaultTheme from './defaultTheme'
import Ids from './Cloak/Ids'
import Cloak from './Cloak'
import Timer from './Timer'
import loopUtil from '~utils/loop'

// Points emerge from around central europe.
const ANCHOR_POINT = new Vector3(0.25, 0, -1.0)

const NORMAL_SCALE_X = -2.235 // 1.49 * 1.5

const NORMAL_SCALE_Y = 0.735 // 0.49 * 1.5 (negative if normalMap's flipY is false)

export const LATI_TRANS = 2 / 90

export const LONG_TRANS = 4 / 180

const MAX_CONNECTIONS = 32

const defaultOffsetFn = () => ({
    x: 0,
    y: 0,
})

function getDefaultActiveLookAtPos() {
    return new Vector3(0.2, 0, -0.75)
}

function getDefaultInactiveLookAtPos() {
    return new Vector3(16 * LONG_TRANS, 0, -66 * LATI_TRANS)
}

export default function NetworkVisualizer(
    canvas,
    {
        offsetFn = defaultOffsetFn,
        setLoading,
        onIntroDone,
        onPan = () => {},
        onSelect = () => {},
        onPositionCorrection,
    },
) {
    let active = false

    let nodeCoordinates

    let metadata

    let highlightedPoint

    let hoveredPoint

    let selectedPoint

    const timer = new Timer()

    let isDragging = false

    let dropTimeout = null

    const colors = {
        ocean: defaultTheme[Ids.MAP_OCEAN],
        land: defaultTheme[Ids.MAP_LAND],
        line: defaultTheme[Ids.LINES_COLOR],
        lineShadow: defaultTheme[Ids.LINES_SHADOW_COLOR],
        node: defaultTheme[Ids.NODES_COLOR],
        highlightedNode: defaultTheme[Ids.NODES_COLOR],
        selectedNode: defaultTheme[Ids.NODES_SELECTED_COLOR],
    }

    let { ratio = 1, width = 2, height = 2 } = {}

    const pixelRatio = width / height

    let renderer

    let anisotropy

    const scene = new Scene()

    scene.background = new Color(0xffffff)

    const sceneMirror = new Scene()

    const lights = new Lights()

    lights.mount(scene)

    const camera = new PerspectiveCamera(60, width / height, 0.1, 20)

    camera.lookAt(new Vector3())

    camera.position.set(0, 1, 1)

    let renderPipeline

    let cloak

    let highlightAnimation

    let staleShadows = false

    let introDone = false

    let staleShadowsTimeoutId

    let autopilot

    function engageAutopilot({ delay = 3500 } = {}) {
        if (autopilot && active) {
            autopilot.engage({
                delay,
            })
        }
    }

    function disengageAutopilot() {
        if (autopilot) {
            autopilot.disengage()
        }
    }

    let selectPoint

    const invalidateShadows = () => {
        if (introDone) {
            staleShadows = true

            clearTimeout(staleShadowsTimeoutId)

            staleShadowsTimeoutId = setTimeout(() => {
                staleShadows = false
            }, 500)
        }
    }

    const planeMaterial = new MeshPhongMaterial({
        color: defaultTheme[Ids.MAP_OCEAN],
        dithering: true,
    })

    planeMaterial.depthWrite = false

    const plane = new Mesh(new PlaneGeometry(100, 100, 1), planeMaterial)

    plane.rotateX(-Math.PI * 0.5)
    plane.position.y = -0.02
    plane.castShadow = false
    plane.receiveShadow = false

    scene.add(plane)

    const particles = new Particles({
        timer,
        onBeforeQualityChange: (particle) => {
            if (particle) {
                scene.remove(particle.instance)
                sceneMirror.remove(particle.instanceMirror)
            }
        },
        onAfterQualityChange: ({ instance, instanceMirror }) => {
            scene.add(instance)
            sceneMirror.add(instanceMirror)
        },
        onIntroDone: () => {
            staleShadows = false
            introDone = true

            autopilot = new Autopilot(particles.getCoordinates(), (point) =>
                selectPoint(point, {
                    autopilot: true,
                    maxConnections: 12,
                }),
            )

            if (!selectedPoint) {
                engageAutopilot()
            }

            if (typeof onIntroDone === 'function') {
                onIntroDone()
            }
        },
    })

    let lines

    let connectedPoints

    let onPointSelect

    function clearSelection() {
        const time = timer.getTime()

        if (selectedPoint) {
            particles.deselect(selectedPoint)
            highlightAnimation.hide(selectedPoint)
            selectedPoint = undefined
        }

        if (connectedPoints) {
            connectedPoints.forEach((point) => {
                particles.deactive(point)
            })

            lines.setTopology(time, undefined)

            connectedPoints = undefined
        }

        if (typeof onSelect === 'function') {
            onSelect(null, {})
        }
    }

    let cancelPostponed

    const nodeCache = {}

    const streamCache = {}

    let fetchNo = 0

    selectPoint = async (
        point,
        { autopilot: autopiloted = false, maxConnections = MAX_CONNECTIONS } = {},
    ) => {
        if (point === selectedPoint) {
            return
        }

        const currentFetchNo = ++fetchNo

        if (typeof cancelPostponed === 'function') {
            // Cancel all pending topology displaying. See below.
            cancelPostponed()
            cancelPostponed = undefined
        }

        clearSelection()

        if (typeof setLoading === 'function') {
            setLoading(false)
        }

        if (!point) {
            return
        }

        selectedPoint = point

        particles.select(point)

        highlightAnimation.show(point)

        invalidateShadows()

        if (typeof onPointSelect === 'function') {
            // "Preflight" animation. We don't know the topology's area so we don't touch it, just
            // center the selected point and that's it.
            onPointSelect(point, {
                area: undefined,
                duration: autopiloted ? 12 : 1,
                showTooltip: false,
            })
        }

        if (typeof setLoading === 'function') {
            setLoading(true)
        }

        const topology = await (async () => {
            try {
                return await getTopology(point, {
                    nodeCache,
                    streamCache,
                    metadata,
                    nodeCoordinates,
                })
            } catch (e) {
                console.warn('Failed to load topology', e)
                return []
            }
        })()

        if (currentFetchNo !== fetchNo) {
            // Skip the rest if another particle has been clicked in the meantime. The one clicked
            // most recently wins.
            return
        }

        if (typeof setLoading === 'function') {
            setLoading(false)
        }

        const partialTopology = [
            ...new Set([...topology.filter(([a, b]) => a === point || b === point), ...topology]),
        ].slice(0, maxConnections)

        const points = new Set([point])

        partialTopology.forEach(([a, b]) => {
            points.add(a)
            points.add(b)
        })

        const coordinates = [...points]

        if (typeof onPointSelect === 'function') {
            onPointSelect(point, {
                area: autopiloted
                    ? 1.0 + Math.random()
                    : getCoordinatesArea(coordinates) * (0.5 + Math.random() * 0.25),
                duration: autopiloted ? 12 : 1,
                showTooltip: true,
            })
        }

        coordinates.forEach((c) => {
            if (c !== point) {
                particles.active(c)
            }
        })

        connectedPoints = coordinates

        const [cancel, postponed] = postpone(250, () => {
            // Let's wait for the current selection to go away.
            lines.setTopology(timer.getTime(), partialTopology)
        })

        // eslint-disable-next-line require-atomic-updates
        cancelPostponed = cancel

        await postponed()
    }

    let controls

    let qa

    const earth = new Earth()

    let phase = 1

    this.setPhase = (newPhase) => {
        phase = newPhase
    }

    const loop = loopUtil(() => {
        const delta = timer.tick()

        const time = timer.getTime()

        qa.check(time, delta)

        controls.update(time, delta)

        onPan(camera)

        if (!isDragging) {
            renderPipeline.update(time)
            particles.update(time)
            lines.update(time)

            if (staleShadows) {
                lights.update()
            }
        }

        earth.render(renderer, {
            oceanColor: colors.ocean,
            landColor: colors.land,
        })

        renderPipeline.render()

        cloak.render(phase, time)
    })

    this.resize = ({ width: w, height: h } = {}) => {
        if (w != null && h != null) {
            width = w
            height = h
            ratio = width / height
            camera.aspect = ratio

            camera.updateProjectionMatrix()
        }

        if (renderPipeline) {
            renderPipeline.setSize(width, height)
        }

        if (renderer) {
            renderer.setSize(width, height)
        }

        if (controls) {
            controls.resize()
        }

        if (cloak) {
            cloak.setScreenSize(width, height)
        }
    }

    const setQuality = (quality) => {
        switch (quality) {
            case 0:
                particles.setQuality(0)
                lights.setCastShadow(false)
                break
            case 1:
                particles.setQuality(0)
                lights.setCastShadow(true)
                break
            default:
                particles.setQuality(1)
                lights.setCastShadow(true)
        }

        renderPipeline.setQuality(quality)

        renderPipeline.setSize(width, height)
    }

    const onBeforeLoad = () => {
        if (renderer) {
            throw new Error('Already loaded')
        }

        renderer = new WebGLRenderer({
            alpha: false,
            antialias: true,
            canvas,
            depth: true,
            logarithmicDepthBuffer: false,
            powerPreference: 'high-performance',
            preserveDrawingBuffer: false,
            stencil: false,
        })

        renderer.setClearColor(0x000000, 1)
        renderer.toneMapping = LinearToneMapping
        renderer.toneMappingExposure = 0.6
        renderer.shadowMap.enabled = true
        renderer.setSize(width, height)
        renderer.setPixelRatio(pixelRatio)

        anisotropy = Math.min(8, renderer.capabilities.getMaxAnisotropy())

        highlightAnimation = new NodeHighlightAnimation()

        highlightAnimation.setColor(new Color(colors.node))

        renderPipeline = new RenderPipeline(renderer, scene, sceneMirror, camera, {
            fullBlur: 0.0,
            glowScale: 2,
            glowStrength: 0.8,
            offsetTilt: new Vector4(1.5, 1.0, 1.5, 1.0),
            radius: 2,
            shift: new Vector4(4.0, 1000.0, 4.0, 1000.0),
        })

        cloak = new Cloak(renderer)

        injectRenderPipeline(renderPipeline, particles.getParticleMaterial())

        // eslint-disable-next-line max-len
        lines = new Lines(
            scene,
            renderPipeline,
            MAX_CONNECTIONS,
            new Color(colors.line),
            new Color(colors.lineShadow),
        )

        qa = new QA(0, 3, setQuality, 2.5)

        earth.inject(renderPipeline)
    }

    this.load = async (onComplete) => {
        onBeforeLoad()

        await highlightAnimation.load().then(scene.add.bind(scene))

        injectRenderPipeline(renderPipeline, highlightAnimation.getMaterial())

        await earth.loadContours({
            anisotropy,
        })

        earth.mount(scene)

        onComplete()

        await earth.loadNormals({
            anisotropy,
        })

        earth.show(NORMAL_SCALE_X, NORMAL_SCALE_Y)
    }

    this.feed = ({
        coordinates: _coordinates,
        metadata: _metadata,
        nodeCoordinates: _nodeCoordinates,
    }) => {
        nodeCoordinates = _nodeCoordinates

        // We're gonna sort coordinates thus we copy it to store the original.
        const coordinates = [..._coordinates]
            .map((point) => [point, ANCHOR_POINT.distanceToSquared(point)])
            .sort(([, distanceA], [, distanceB]) => distanceA - distanceB)
            .map(([point]) => point)

        const timing = new WeakMap()

        coordinates.forEach((point, i) => {
            timing.set(point, i * (0.005 + 0.015 * (i / coordinates.length)))
        })

        metadata = _metadata

        particles.setCoordinates(coordinates, timing)

        staleShadows = true
    }

    const getIntroedHit = (x, y) => {
        if (!active) {
            return undefined
        }

        const point = getHits(x, y, width, height, particles.getCoordinates(), camera)[0]

        if (particles.isIntroDone(point)) {
            return point
        }

        return undefined
    }

    function onDragEnd() {
        dropTimeout = setTimeout(() => {
            isDragging = false
            timer.resume()
        }, 250)
    }

    function onDragStart() {
        clearTimeout(dropTimeout)

        isDragging = true
        timer.pause()
    }

    function onPointerDown() {
        if (hoveredPoint && hoveredPoint !== selectedPoint) {
            canvas.style.cursor = 'pointer'

            particles.down(hoveredPoint)
        }

        disengageAutopilot()
    }

    function onHover({ x, y }) {
        const hit = getIntroedHit(x, y)

        hoveredPoint = hit

        const isCurrent = !!hit && (hit === highlightedPoint || hit === selectedPoint)

        if (highlightedPoint && !isCurrent) {
            if (highlightedPoint !== selectedPoint) {
                invalidateShadows()
                particles.dehighlight(highlightedPoint)
            }

            highlightedPoint = undefined // Empty `hit`.
        }

        if (hit && !isCurrent) {
            canvas.style.cursor = 'pointer'

            invalidateShadows()

            if (particles.highlight(hit)) {
                highlightedPoint = hit
            }
        }

        if (!hit) {
            canvas.style.cursor = 'default'
        }

        if (hit) {
            disengageAutopilot()
            return
        }

        if (!hit && !selectedPoint) {
            engageAutopilot()
        }
    }

    async function onClick({ target, x, y }) {
        const hit = getIntroedHit(x, y)

        try {
            await selectPoint(hit)
        } catch (e) {
            console.warn('Failed to complete point selection', e)
        }

        if (!hit) {
            if (active) {
                target.resetZoom()
            }

            engageAutopilot()
        }
    }

    function enableControls() {
        controls.humanViewer.enable()

        controls.resetZoom()

        controls.moveTo(getDefaultActiveLookAtPos())

        controls.disableLiveness()
    }

    const onBeforeFirstStart = () => {
        this.resize()

        scene.background = new Color(0x0)

        const lookAtPosition = active ? getDefaultActiveLookAtPos() : getDefaultInactiveLookAtPos()

        controls = new Controls(canvas, {
            camera,
            lookAtPosition,
            onClick,
            onDragEnd,
            onDragStart,
            onHover,
            onPointerDown,
            zoomLevels: [0.9, 0.7, 1.0, 0.8],
            onPositionCorrection,
        })

        if (active) {
            enableControls()
        } else {
            controls.enableLiveness()
            controls.humanViewer.disable()
            controls.resetZoom()
            controls.moveTo(getDefaultInactiveLookAtPos())
        }

        onPointSelect = (point, { area, showTooltip = true, duration = 1.0 } = {}) => {
            controls.moveTo(point, area, duration, offsetFn(canvas.width))

            onSelect(point, {
                metadata: metadata.get(point),
                showTooltip,
            })
        }

        if (cloak) {
            cloak.uncover()
        }
    }

    const onAfterFirstStart = () => {
        lights.intro()
    }

    let visibilityCheck

    let stopLoop

    let killed = false

    this.start = () => {
        if (stopLoop || killed) {
            return
        }

        if (typeof document === 'undefined') {
            return
        }

        if (!visibilityCheck) {
            visibilityCheck = () => {
                document.hidden ? this.stop() : this.start()
            }

            // Pause if tab isn't visible
            document.addEventListener('visibilitychange', visibilityCheck, false)
        }

        if (document.hidden) {
            return
        }

        const isFirstStart = !controls

        if (isFirstStart) {
            onBeforeFirstStart()
        }

        timer.start()

        stopLoop = loop()

        if (isFirstStart) {
            onAfterFirstStart()
        }

        if (!selectedPoint) {
            engageAutopilot()
        }
    }

    this.stop = () => {
        if (!stopLoop) {
            return
        }

        disengageAutopilot()
        timer.stop()
        stopLoop()
        stopLoop = undefined
    }

    this.kill = () => {
        killed = true

        this.stop()

        canvas.style.cursor = 'default'

        if (typeof onSelect === 'function') {
            onSelect(null, {})
        }

        renderer?.dispose()

        if (controls) {
            controls.humanViewer.disable()
        }

        controls = undefined
        autopilot = undefined
        cloak = undefined
        highlightAnimation = undefined
        lines = undefined
        qa = undefined
        renderer = undefined
    }

    this.set = (key, value) => {
        switch (key) {
            case Ids.MAP_OCEAN:
                colors.ocean = value
                planeMaterial.color = new Color(value)
                planeMaterial.needsUpdate = true

                if (highlightAnimation) {
                    highlightAnimation.setColor(new Color(value))
                }
                break
            case Ids.MAP_LAND:
                colors.land = value
                break
            case Ids.LINES_COLOR:
                colors.line = value

                if (lines) {
                    lines.setColor(new Color(value))
                }
                break
            case Ids.LINES_SHADOW_COLOR:
                colors.lineShadow = value

                if (lines) {
                    lines.setColor(new Color(value), {
                        shadow: true,
                    })
                }
                break
            case Ids.NODES_COLOR:
                colors.node = value
                particles.setDefaultColor(new Color(value))
                break
            case Ids.NODES_CONNECTED_COLOR:
                particles.setConnectedColor(new Color(value))
                break
            case Ids.NODES_HIGHLIGHTED_COLOR:
                particles.setHighlightColor(new Color(value))
                break
            case Ids.NODES_SELECTED_COLOR:
                particles.setSelectColor(new Color(value))
                break
            default:
                cloak.setParam(key, value)
        }
    }

    let deactivatedSelection

    this.activate = () => {
        active = true

        if (controls) {
            enableControls()
        }
    }

    this.reselect = async () => {
        if (deactivatedSelection) {
            try {
                await selectPoint(deactivatedSelection)
            } catch (e) {
                console.warn('Failed to complete point selection', e)
            }

            return
        }

        engageAutopilot({
            delay: 0,
        })
    }

    this.deactivate = async () => {
        active = false

        deactivatedSelection = autopilot && autopilot.isEngaged() ? null : selectedPoint

        if (controls) {
            controls.humanViewer.disable()
        }

        canvas.style.cursor = 'default'

        try {
            await selectPoint(null)
        } catch (e) {
            console.warn('Failed to complete point selection', e)
        }

        disengageAutopilot()

        if (controls) {
            controls.enableLiveness()
            controls.resetZoom()
            controls.moveTo(getDefaultInactiveLookAtPos())
        }
    }
}
