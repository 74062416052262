import { get } from './fetchData'

const MAPBOX_TOKEN =
    'pk.eyJ1IjoibWF0dGlubmVzIiwiYSI6ImNrNWhrN2FubDA0cGgzam1ycHV6Nmg2dHoifQ.HC5_Wu1R-OqRLza1u6P3Ig'

export default async function getReversedGeocodedLocation({ longitude, latitude }) {
    let result

    try {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${MAPBOX_TOKEN}`

        result = await get(url)
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Failed to reverse geocode ${longitude},${latitude}`)
    }

    // eslint-disable-next-line camelcase
    const { place_name: region, bbox } =
        ((result && result.features) || []).find(({ place_type }) =>
            place_type.includes('region'),
        ) || {}

    return {
        region,
        bbox,
    }
}
