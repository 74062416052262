import { Link } from 'gatsby'
import React, { useState } from 'react'
import { ButtonSize, PrimaryButton } from 'streamr-ui'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled, { css } from 'styled-components'
import Br from '~shared/Br'
import Faq from '~shared/Faq'
import PrestyledLayout from '~shared/Layout'
import Spacer from '~shared/Spacer'
import Tatum from '~shared/Tatum'
import Topic from '~shared/Topic'
import { TopQAs } from '~utils/faqs'
import urls from '~utils/urls'
import Hero from './Hero'
import IntroVideo from './IntroVideo'
import Partners, { PartnersTopic } from './Partners'
import builder from './assets/builder.png'
import builder2x from './assets/builder@2x.png'
import builderTablet from './assets/builder_tablet.png'
import builderTablet2x from './assets/builder_tablet@2x.png'
import nodeRunner from './assets/node_runner.png'
import nodeRunner2x from './assets/node_runner@2x.png'
import nodeRunnerTablet from './assets/node_runner_tablet.png'
import nodeRunnerTablet2x from './assets/node_runner_tablet@2x.png'
import tokenHolder from './assets/token_holder.png'
import tokenHolder2x from './assets/token_holder@2x.png'
import tokenHolderTablet from './assets/token_holder_tablet.png'
import tokenHolderTablet2x from './assets/token_holder_tablet@2x.png'
import ExternalLink from './components/ExternalLink'
import { LearnStreamrColumn } from './components/LearnStreamr'
import LearnStreamrNav, { LearnStreamrNavMobileWrap } from './components/LearnStreamrNav'
import LearnStreamrSlide, {
    LearnStreamrSlider,
    LearnStreamrTopic,
} from './components/LearnStreamrSlide'
import Persona, { PersonaButtonPaint, PersonaList, PersonaListColumn } from './components/Persona'
import SocialChannels from './components/SocialChannels'
import StayInformedNewsletterForm from './components/StayInformedNewsletterForm'
import StayInformedTile, {
    DiscordIcon,
    NewsletterIcon,
    StayInformedColumn,
    StayInformedGrid,
} from './components/StayInformedTile'
import TweetWall from './TweetWall'

const CustomTopic = styled(Topic)`
    ${Topic.Paragraph} {
        text-align: center;
        margin-top: 2.5rem;
        padding-left: 4rem;
        padding-right: 4rem;
    }
`

const Layout = styled(PrestyledLayout)`
    color: #0c009a;

    ${PrestyledLayout.Inner} {
        padding: 0;
    }
`

const n = 5

const Ids = {
    StartYourStreamrJourney: 'start-your-streamr-journey',
    WatchOurVideo: 'watch-our-video',
    StayInformed: 'stay-informed',
    LearnAboutStreamr: 'learn-about-streamr',
    Faq: 'faq',
    Tatum: 'tatum',
    Tweets: 'tweets',
}

const Top = () => {
    const [slideNo, setSlideNo] = useState(0)

    function nextSlide() {
        setSlideNo((c) => Math.min(n - 1, c + 1))
    }

    function prevSlide() {
        setSlideNo((c) => Math.max(0, c - 1))
    }

    return (
        <Layout nav={<Hero />} $backgroundColor="#F5F5F5">
            <PartnersTopic pad head="3.75rem,5rem,8rem" tail="3.75rem,5rem,0">
                <Partners />
            </PartnersTopic>
            <div id={Ids.StartYourStreamrJourney} />
            <CustomTopic pad head="6rem,9rem,13.75rem">
                <Title $appear $center $pad>
                    <a href={`#${Ids.StartYourStreamrJourney}`}>Start your Streamr&nbsp;journey</a>
                </Title>
                <Spacer head="3rem,5.5rem,7.5rem">
                    <PersonaListColumn>
                        <PersonaList>
                            <Persona
                                title="Node runners"
                                desc={
                                    <p $appear>
                                        Run a Streamr node from your Mac or&nbsp;PC, secure the
                                        network and earn yield on staked $DATA tokens. Anyone can
                                        do&nbsp;it.
                                    </p>
                                }
                                image={
                                    <img
                                        src={nodeRunner}
                                        srcSet={`${nodeRunner2x} 2x`}
                                        alt="Node runner"
                                    />
                                }
                                tabletImage={
                                    <img
                                        src={nodeRunnerTablet}
                                        srcSet={`${nodeRunnerTablet2x} 2x`}
                                        alt="Node runner"
                                    />
                                }
                                backgroundColors={['#DBDFFA', '#DBF1F2']}
                                callToAction={
                                    <PrimaryButton
                                        size={ButtonSize.Moose}
                                        paint={PersonaButtonPaint}
                                        tag="a"
                                        href={urls.stakeAndEarn}
                                        blank
                                    >
                                        Stake and earn
                                    </PrimaryButton>
                                }
                            />
                            <Persona
                                title="Token holders"
                                desc={
                                    <p $appear>
                                        The $DATA token is the backbone of&nbsp;the Streamr network.
                                        Help drive adoption and liquidity by acquiring
                                        the&nbsp;token.
                                    </p>
                                }
                                image={
                                    <img
                                        src={tokenHolder}
                                        srcSet={`${tokenHolder2x} 2x`}
                                        alt="Node runner"
                                    />
                                }
                                tabletImage={
                                    <img
                                        src={tokenHolderTablet}
                                        srcSet={`${tokenHolderTablet2x} 2x`}
                                        alt="Node runner"
                                    />
                                }
                                backgroundColors={['#F5F1F8', '#F7E8E0']}
                                callToAction={
                                    <PrimaryButton
                                        size={ButtonSize.Moose}
                                        paint={PersonaButtonPaint}
                                        tag="a"
                                        href={urls.token}
                                        blank
                                    >
                                        Discover our Token
                                    </PrimaryButton>
                                }
                            />
                            <Persona
                                title="Builders"
                                desc={
                                    <p $appear>
                                        Build on data streams that support your dApp tech stack,
                                        with the Streamr&nbsp;SDK.
                                        <br />
                                        &zwnj;
                                    </p>
                                }
                                image={
                                    <img
                                        src={builder}
                                        srcSet={`${builder2x} 2x`}
                                        alt="Node runner"
                                    />
                                }
                                tabletImage={
                                    <img
                                        src={builderTablet}
                                        srcSet={`${builderTablet2x} 2x`}
                                        alt="Node runner"
                                    />
                                }
                                backgroundColors={['#ECEBF3', '#E0E4FF']}
                                callToAction={
                                    <PrimaryButton
                                        size={ButtonSize.Moose}
                                        paint={PersonaButtonPaint}
                                        tag="a"
                                        href={urls.devs}
                                        blank
                                    >
                                        Start to build
                                    </PrimaryButton>
                                }
                            />
                        </PersonaList>
                    </PersonaListColumn>
                </Spacer>
            </CustomTopic>
            <div id={Ids.WatchOurVideo} />
            <CustomTopic pad head="6rem,9rem,13.75rem">
                <Title $center hash="intro-video" $appear $pad>
                    <a href={`#${Ids.WatchOurVideo}`}>Watch our&nbsp;video</a>
                </Title>
                <Topic.Body>
                    <Topic.Paragraph $appear>
                        The Streamr Network provides a bulletproof pub-sub data transport
                        <Br tablet desktop />
                        layer that allows you to connect your data to the rest of the world.
                        <Br tablet desktop />
                        Watch for a quick overview of how it&nbsp;works.
                    </Topic.Paragraph>
                </Topic.Body>
                <Spacer head="3rem,5.5rem,7.5rem">
                    <IntroVideo />
                </Spacer>
            </CustomTopic>
            <div id={Ids.StayInformed} />
            <Topic pad head="6rem,9rem,13.75rem">
                <Title $appear $center $pad>
                    <a href={`#${Ids.StayInformed}`}>Stay informed</a>
                </Title>
                <StayInformedColumn>
                    <Spacer head="3rem,5.5rem,7.5rem">
                        <StayInformedGrid>
                            <StayInformedTile
                                title="Join us on Discord"
                                subtitle={
                                    <>
                                        DAO governance, technical support, developer grants, earn
                                        NFTs and&nbsp;more.
                                    </>
                                }
                                backgroundColor="#6240AF"
                                callToAction={
                                    <ExternalLink href={urls.community.discord}>
                                        Explore<span> Streamr </span> Discord
                                    </ExternalLink>
                                }
                            >
                                <DiscordIcon />
                            </StayInformedTile>
                            <StayInformedTile
                                title={<>Sign up for our monthly&nbsp;newsletter</>}
                                backgroundColor="#0324FF"
                                callToAction={<StayInformedNewsletterForm />}
                            >
                                <NewsletterIcon />
                            </StayInformedTile>
                        </StayInformedGrid>
                    </Spacer>
                    <Spacer head="1rem,,1.5rem" tail="9rem,9.25rem,13rem">
                        <SocialChannels />
                    </Spacer>
                </StayInformedColumn>
            </Topic>
            <div id={Ids.Tweets} />
            <TweetWallTopic head="4rem,8rem,10rem" pad $cutoff>
                <Title $appear $center $pad>
                    <a href={`#${Ids.Tweets}`}>See how Web3 devs use Streamr</a>
                </Title>
                <TweetWall>
                    {(content, nav) => (
                        <Spacer head="3.5rem,6.5rem," tail="6rem,8rem,9rem" pad>
                            {content}
                            {nav && <Spacer head="5rem,,">{nav}</Spacer>}
                        </Spacer>
                    )}
                </TweetWall>
            </TweetWallTopic>
            <div id={Ids.LearnAboutStreamr} />
            <LearnStreamrTopic pad head="4rem,8rem,10rem" tail="3.75rem,11rem,13.75rem" $cutoff>
                <LearnStreamrColumn>
                    <LearnStreamrHeader>
                        <Title $color="white" $appear>
                            <a href={`#${Ids.LearnAboutStreamr}`}>Learn about Streamr</a>
                        </Title>
                        <LearnStreamrNav
                            slideNo={slideNo}
                            count={n}
                            onNext={nextSlide}
                            onPrev={prevSlide}
                        />
                    </LearnStreamrHeader>
                    <Spacer head="2rem,4.5rem,7.5rem">
                        <LearnStreamrSlider
                            slideNo={slideNo}
                            onSwipedLeft={nextSlide}
                            onSwipedRight={prevSlide}
                        >
                            <LearnStreamrSlide
                                url="https://www.youtube.com/watch?v=rG4ojFECNKY"
                                title={<>Web3 Core Infrastructure at&nbsp;ETHDenver</>}
                                category="Streamr Network"
                                numViews="380 views"
                                publishedAt="A year ago"
                                duration="19:20"
                            />
                            <LearnStreamrSlide
                                url="https://www.youtube.com/watch?v=uuwxxhIXeKU"
                                title={<>Building on Streamr: DIMO with Andy &amp; Yev</>}
                                category="Streamr Network"
                                numViews="10K views"
                                publishedAt="A year ago"
                                duration="20:18"
                            />
                            <LearnStreamrSlide
                                url="https://www.youtube.com/watch?v=6hbg_xMPCjs"
                                category="Streamr Network"
                                title={<>The Streamr Network Explained Part One:&nbsp;Pub/Sub</>}
                                numViews="4.6K views"
                                publishedAt="A year ago"
                                duration="7:01"
                            />
                            <LearnStreamrSlide
                                url="https://www.youtube.com/watch?v=H2_lM40O7Z0"
                                category="Streamr Network"
                                title={<>Streamr Governance AMA Q4/2022</>}
                                numViews="7.5K views"
                                publishedAt="5 months ago"
                                duration="1:06:50"
                            />
                            <LearnStreamrSlide
                                url="https://www.youtube.com/watch?v=BZ4UPFx6zho"
                                category="Streamr Network"
                                title={<>RedStone interview &mdash; next generation oracles</>}
                                numViews="325 views"
                                publishedAt="3 months ago"
                                duration="16:19"
                            />
                        </LearnStreamrSlider>
                    </Spacer>
                </LearnStreamrColumn>
                <LearnStreamrNavMobileWrap>
                    <LearnStreamrNav
                        slideNo={slideNo}
                        count={n}
                        onNext={nextSlide}
                        onPrev={prevSlide}
                        invisibleOnTabletAndAbove
                    />
                </LearnStreamrNavMobileWrap>
            </LearnStreamrTopic>
            <div id={Ids.Faq} />
            <Topic pad head="9rem,,11.5rem">
                <Faq header>
                    <TopQAs />
                </Faq>
                <Centerer head="3.75rem,5rem,5.5rem">
                    <div $appear>
                        <PrimaryButton
                            tag={Link}
                            to="/faq"
                            paint={css`
                                --radius: 0.5rem;
                            `}
                            size={ButtonSize.Moose}
                        >
                            More answers on FAQ
                        </PrimaryButton>
                    </div>
                </Centerer>
            </Topic>
            <div id={Ids.Tatum} />
            <Topic pad head="7.5rem,9rem,19rem">
                <Tatum />
            </Topic>
        </Layout>
    )
}

export default Top

const Title = styled.h2`
    color: ${({ $color }) => $color};
    font-size: 1.75rem;
    line-height: 1.4em;
    margin: 0;
    text-align: ${({ $center = false }) => ($center ? 'center' : undefined)};
    padding: ${({ $pad = false }) => ($pad ? '0 2rem' : undefined)};

    a {
        color: inherit !important;
        text-decoration: none !important;
    }

    @media ${MqTablet} {
        font-size: 2.5rem;
    }

    @media ${MqDesktop} {
        font-size: 3.75rem;
    }
`

const LearnStreamrHeader = styled.div`
    align-items: center;
    display: flex;

    ${Title} {
        flex-grow: 1;
    }

    ${Title} + * {
        display: none;
    }

    @media ${MqTablet} {
        ${Title} + * {
            display: grid;
        }
    }
`

const Centerer = styled(Spacer)`
    text-align: center;
`

const TweetWallTopic = styled(Topic)`
    background: #e6e6f4;
    background: linear-gradient(rgba(230, 230, 244, 1), rgba(232, 233, 247, 0));

    ${Title} {
        margin-left: auto;
        margin-right: auto;
        width: 11em;
    }
`
